import { GeneralDynamicModule } from './GeneralDynamicModule/GeneralDynamicModule';
import { convertDynamicModulesResponseToDynamicModulesData } from './utils';

import { useExperiments } from 'lib/experiment';
import { ExperimentName } from 'lib/experiment/experimentConfig';

import { ProductCardData } from 'components/ProductGrid/ProductCard/types';
import { DynamicModuleResponse } from 'types/generated/api';

import styles from './DynamicModulesManager.module.scss';

export enum PageTrackingIdentifier {
  HOMEPAGE = 'HOMEPAGE',
}

export type DynamicModuleModule = 'GENERAL';

export type DynamicModuleSegment = 'BEST_SELLERS';

export type DynamicModuleData = {
  identifier?: string;
  module: DynamicModuleModule;
  products?: ProductCardData[];

  segment: DynamicModuleSegment;
};

type DynamicModulesManagerProps = {
  dynamicModulesResponse: DynamicModuleResponse[];
  pageTrackingIdentifier: PageTrackingIdentifier;
};

export const DynamicModulesManager = ({
  dynamicModulesResponse,
  pageTrackingIdentifier,
}: DynamicModulesManagerProps) => {
  const { experiments: { enableHomepageDynamicModule } = {}, loading } =
    useExperiments(ExperimentName.enableHomepageDynamicModule);

  if (loading || !enableHomepageDynamicModule) {
    return null;
  }

  const modules: DynamicModuleData[] =
    convertDynamicModulesResponseToDynamicModulesData(dynamicModulesResponse);

  const propagateDynamicModules = () => {
    return modules.map(module => {
      switch (module.module) {
        case 'GENERAL': {
          return (
            <GeneralDynamicModule
              dynamicModuleData={module}
              pageTrackingIdentifier={pageTrackingIdentifier}
            />
          );
        }
      }
    });
  };

  const dynamicModulesManagerContainer = (
    <div className={styles.dynamicModulesManager}>
      {propagateDynamicModules()}
    </div>
  );

  const shouldRender =
    dynamicModulesResponse && dynamicModulesResponse.length !== 0;
  return <>{shouldRender && dynamicModulesManagerContainer}</>;
};
